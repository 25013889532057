'use client';

import { useEffect, useState } from 'react';
import Image, { ImageProps } from 'next/image';

type ImageFallBackProps = Omit<ImageProps, 'src'> & {
  fallback?: string;
  src?: string;
};

const ImageWithFallback = ({
  fallback = '/images/fallbackImage.png',
  width,
  height,
  sizes,
  className,
  priority,
  alt,
  src: srcProp,
  quality = 100,
  ...props
}: ImageFallBackProps) => {
  const [src, setSrc] = useState(srcProp);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (error) {
      setSrc(fallback);
    } else if (src !== srcProp) setSrc(srcProp);
  }, [srcProp, setSrc, src, error, fallback]);

  return (
    <Image
      alt={alt}
      src={src || fallback}
      onError={() => setError(true)}
      quality={quality}
      width={width}
      height={height}
      sizes={sizes}
      className={className}
      priority={priority}
      {...props}
    />
  );
};

export default ImageWithFallback;
