'use client';

import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useRouter } from 'next/navigation';
import TextInput from '@/components/common/textInput/TextInput';
import IconButton from '@/components/common/iconButton/IconButton';
import Search from '@/components/svg/Search';

type SearchInputProps = {
  name: string;
  expanded: boolean;
  setExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const SearchInput = ({
  name,
  expanded,
  setExpanded,
  setIsDrawerOpen,
}: SearchInputProps) => {
  const { replace } = useRouter();

  const [query, setQuery] = useState('');
  const searchResultsPathname = 'search-results';

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && query) {
      replace(`/${searchResultsPathname}?q=${query}`);
      setQuery('');
      if (setIsDrawerOpen) {
        setIsDrawerOpen(false);
      }
    }
  };

  const handleOnClick = () => {
    // when desktop, toggle showing/hiding search input
    if (setExpanded) {
      setExpanded((prev) => !prev);
    }
    // when mobile, close drawer after clicking search icon
    if (setIsDrawerOpen) {
      setIsDrawerOpen(false);
    }
    // if there is a valid query, navigate to search results and clear search input
    if (query.trim().length > 0) {
      replace(`/${searchResultsPathname}?q=${query}`);
      setQuery('');
    }
  };

  return (
    <div className='flex gap-2'>
      {expanded && (
        <TextInput
          id='query'
          name={name}
          value={query}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          placeholder='Search press releases'
          fullWidth
        />
      )}
      <IconButton
        ariaLabelText='Search'
        className='p-2'
        onClick={handleOnClick}
      >
        <Search />
      </IconButton>
    </div>
  );
};

export default SearchInput;
