import { SVGProps } from '@/types/svg';
import Svg from './Svg';

const HamburgerMenu = ({
  width = '24',
  height = '24',
  fill = '#FFFFFF',
}: SVGProps) => (
  <Svg
    data-testid='hamburger-menu'
    width={width}
    height={height}
    fill={fill}
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    color='#FFFFFF'
  >
    <line x1='3' x2='21' y1='6' y2='6' />
    <line x1='3' x2='21' y1='12' y2='12' />
    <line x1='3' x2='21' y1='18' y2='18' />
  </Svg>
);

export default HamburgerMenu;
