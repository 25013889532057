import cx from 'classnames';
import NextLink, { LinkProps as BaseLinkProps } from 'next/link';
import { MouseEventHandler } from 'react';

type LinkProps = {
  ariaLabelText?: string;
  children: React.ReactNode;
  href: string;
  next: boolean;
  target?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
} & BaseLinkProps;

const Link = ({
  ariaLabelText,
  children,
  href,
  next,
  target = '_blank',
  onClick,
  prefetch = false,
  className: classNameProp,
}: LinkProps) => {
  const internalLink = (
    <NextLink
      aria-label={ariaLabelText}
      onClick={onClick}
      prefetch={prefetch}
      href={href}
      className={cx('text-content-link', classNameProp)}
    >
      {children}
    </NextLink>
  );

  const externalLink = (
    <a
      aria-label={ariaLabelText}
      href={href}
      onClick={onClick}
      target={target}
      rel='noreferrer'
      className={cx('text-content-link', classNameProp)}
    >
      {children}
    </a>
  );

  return next ? internalLink : externalLink;
};

export default Link;
