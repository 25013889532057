import { SVGProps } from '@/types/svg';
import Svg from './Svg';

const Search = ({
  width = '24',
  height = '24',
  color = '#FFFFFF',
}: SVGProps) => (
  <Svg
    data-testid='search'
    width={width}
    height={height}
    color={color}
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <circle cx='11' cy='11' r='8' />
    <path d='m21 21-4.3-4.3' />
  </Svg>
);

export default Search;
