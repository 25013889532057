import type { ButtonProps } from '../button/Button';

type IconButtonProps = ButtonProps & {
  children: React.ReactNode;
  ariaLabelText: string;
};

const IconButton = ({ ariaLabelText, children, ...props }: IconButtonProps) => {
  return (
    <button
      type='button'
      aria-label={ariaLabelText}
      className='inline-flex items-center gap-x-2 text-sm font-semibold cursor-pointer rounded text-grey-d4 hover:bg-brand-primaryShade disabled:opacity-50 disabled:pointer-events-none'
      {...props}
    >
      {children}
    </button>
  );
};

export default IconButton;
