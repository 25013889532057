'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { IStaticMethods } from 'preline/preline';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

// Retry autoInit if not initialized
export const initPreline = (times = 0) => {
  setTimeout(
    () => {
      try {
        window.HSStaticMethods.autoInit();
      } catch (error) {
        if (times <= 3) {
          initPreline(times + 1);
        } else {
          throw error;
        }
      }
    },
    100 * (times + 1)
  );
};

const PrelineScript = () => {
  const path = usePathname();

  useEffect(() => {
    import('preline/preline');
  }, []);

  useEffect(() => {
    initPreline();
  }, [path]);

  return null;
};

export default PrelineScript;
