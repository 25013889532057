'use client';

import SocialMedia from '@/components/footer/SocialMedia';
import Group from '@/components/footer/Group';
import Link from '@/components/common/link/Link';
import FanDuelLogoHorizontal from '@/components/svg/FanDuelLogoHorizontal';
import useMenu from '@/utils/hooks/useMenu';
import useMedia from '@/utils/hooks/useMedia';
import { MENU } from '@/constants/menu';
import { SCREENS } from '@/constants/media';

const Footer = () => {
  const { data, isLoading, isError } = useMenu();
  const footerData = data?.[MENU.FOOTER] ?? [];
  const isMobile = useMedia(`(max-width: ${SCREENS.MD})`);
  const logoWidth = isMobile ? 50 : 42;
  const logoHeight = isMobile ? 140 : 193.5;

  if (isError) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  return (
    <footer className='bg-marketing-navy py-16'>
      <div className='max-w-[76.875rem] w-[90%] m-auto'>
        <div className='flex flex-col lg:flex-row lg:justify-between lg:items-start'>
          <Link ariaLabelText='Footer FanDuel Logo' next href='/'>
            <div className='mt-[-w-[150px] h-[56px] lg:ml-0 lg:w-[311px] lg:h-[110px]'>
              <FanDuelLogoHorizontal
                height={logoWidth}
                width={logoHeight}
                fill='#FFFFFF'
              />
            </div>
          </Link>
          <div className='flex flex-col lg:flex-row'>
            {footerData.map((mItem) => (
              <div key={mItem?.name} className='lg:ml-10 mb-4'>
                <Group name={mItem?.name} submenu={mItem?.submenu} />
              </div>
            ))}
          </div>
        </div>
        <SocialMedia />
      </div>
    </footer>
  );
};

export default Footer;
