import { useQuery } from '@tanstack/react-query';
import { GetAllPrMenuQuery } from '@/graphql/gql/graphql';
import { MENU } from '@/constants/menu';
import { getMenus } from '../api';

const useMenu = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['menu'],
    queryFn: () => getMenus(),
    select: (response: GetAllPrMenuQuery) => {
      const allMenus = response.allPrMenu ?? [];
      return {
        [MENU.HEADER]: allMenus.find((menu) => menu.name === MENU.HEADER)
          ?.menuItems,
        [MENU.FOOTER]: allMenus.find((menu) => menu.name === MENU.FOOTER)
          ?.menuItems,
      };
    },
  });

  return { data, isError, isLoading };
};

export default useMenu;
