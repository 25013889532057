'use client';

import logger from '@/utils/logger';
import { useEffect, useRef } from 'react';
import { unstable_noStore as noStore } from 'next/cache';

const Rum = () => {
  noStore();
  const initialized = useRef(false);

  const applicationId = process.env.DD_APP_ID || '22';
  const clientToken = process.env.APP_TOKEN || '22';
  const env = process.env.DD_ENV;
  const service = process.env.DD_SERVICE;
  const version = process.env.DD_VERSION;
  const traceEnabled = process.env.DD_TRACE_ENABLED;

  useEffect(() => {
    async function injectDD() {
      const dataDogRum = await import('@datadog/browser-rum-slim').then(
        (module) => module.datadogRum
      );
      dataDogRum.init({
        applicationId,
        clientToken,
        site: 'datadoghq.com',
        env,
        service,
        version,
        sessionSampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
    }

    // only initialize once
    if (!initialized.current) {
      initialized.current = true;

      if (traceEnabled === 'true') {
        injectDD().catch((e) => logger.error(e));
      }
    }
  }, []);

  return null;
};

export default Rum;
