import { SVGProps } from '@/types/svg';
import Svg from './Svg';

const Close = ({ width = '24', height = '24' }: SVGProps) => (
  <Svg
    data-testid='close'
    width={width}
    height={height}
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    color='#FFFFFF'
  >
    <path d='M18 6 6 18' />
    <path d='m6 6 12 12' />
  </Svg>
);

export default Close;
