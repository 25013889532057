import Svg from './Svg';
import { SVGProps } from '@/types/svg';

const ArrowDown = ({
  width = '17',
  height = '17',
  fill = '#FFFFFF',
}: SVGProps) => (
  <Svg
    data-testid='arrow-down'
    width={width}
    height={height}
    viewBox='0 0 17 17'
    fill='none'
  >
    <path
      d='M12.8523 6.14625C12.6573 5.95125 12.3403 5.95125 12.1453 6.14625L8.49925 9.79225L4.85325 6.14625C4.65825 5.95125 4.34125 5.95125 4.14625 6.14625C3.95125 6.34125 3.95125 6.65825 4.14625 6.85325L8.14625 10.8533C8.24425 10.9513 8.37225 10.9992 8.50025 10.9992C8.62825 10.9992 8.75625 10.9503 8.85425 10.8533L12.8542 6.85325C13.0472 6.65825 13.0472 6.34225 12.8523 6.14625Z'
      fill={fill}
    />
  </Svg>
);

export default ArrowDown;
