import { InputHTMLAttributes } from 'react';

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  fullWidth?: boolean;
};

const TextInput = ({
  id,
  value,
  name,
  onChange,
  onKeyDown,
  placeholder,
  defaultValue,
  endAdornment,
  startAdornment,
  fullWidth,
  ...props
}: TextInputProps) => {
  return (
    <div data-testid={id} className={`relative ${fullWidth && 'w-full'}`}>
      <div className='flex'>
        <input
          type='text'
          id={id}
          name={name}
          className='py-[0.62rem] px-[0.75rem] block w-full border-grey-l3 rounded focus:border-blue-d1 focus:ring-0 disabled:opacity-50 disabled:pointer-events-none shadow-none'
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          defaultValue={defaultValue}
          {...props}
        />
      </div>
      <div className='absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4'>
        {startAdornment}
      </div>
      <div className='absolute inset-y-0 end-0 flex items-center pointer-events-none z-20 pe-4'>
        {endAdornment}
      </div>
    </div>
  );
};

export default TextInput;
