'use client';

import MobileNavigation from './MobileNavigation/MobileNavigation';
import DesktopNavigation from './DesktopNavigation/DesktopNavigation';
import useMenu from '@/utils/hooks/useMenu';
import { HEADER_HEIGHT_SM, HEADER_HEIGHT_LG } from '@/constants/header';
import { MENU } from '@/constants/menu';

type HeaderProps = {
  bgTransition: boolean;
};

const Header = ({ bgTransition }: HeaderProps) => {
  const { data, isLoading, isError } = useMenu();
  const headerData = data?.[MENU.HEADER] ?? [];

  if (isError) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  return (
    <div data-testid='header'>
      <div className='hidden lg:block lg:w-full'>
        <div className='w-full fixed top-0 z-50'>
          <DesktopNavigation
            menuItems={headerData}
            bgTransition={bgTransition}
          />
        </div>
        {bgTransition ? null : <div style={{ height: HEADER_HEIGHT_LG }} />}
      </div>
      <div className='w-full lg:hidden'>
        <div className='w-full fixed top-0 z-50'>
          <MobileNavigation menuItems={headerData} />
        </div>
        <div style={{ height: HEADER_HEIGHT_SM }} />
      </div>
    </div>
  );
};

export default Header;
