import { usePathname } from 'next/navigation';
import cx from 'classnames';
import Link from '@/components/common/link/Link';
import ArrowDown from '@/components/svg/ArrowDown';
import type { PrMenuItem } from '@/graphql/gql/graphql';

type DropdownProps = {
  menuName: PrMenuItem['name'];
  menuLink: PrMenuItem['link'];
  submenu: PrMenuItem['submenu'];
  color: boolean;
  onClick?: () => void;
};

const Dropdown = ({
  menuName,
  menuLink: menuLinkProp,
  submenu,
  onClick,
  color,
}: DropdownProps) => {
  const filteredSubmenu = submenu ?? [];
  const baseLinkStyles = 'leading-7 border-b';

  // current page's menu group link (e.g. our-company, resources, etc...)
  const pathname = usePathname();
  const currentLink =
    pathname.split('/')[1] === '' ? '/' : pathname.split('/')[1];

  // menu group link (e.g. our-company, resources, etc...)
  const menuLink =
    menuLinkProp?.split('/')[1] === '' ? '/' : menuLinkProp?.split('/')[1];

  // menu is active if current link is same as the menu link
  const activeDropdown = menuLink === currentLink;

  // if there is no submenu
  if (filteredSubmenu.length === 0) {
    return (
      <>
        {menuName && menuLink ? (
          <button
            type='button'
            aria-label={menuName}
            className='flex items-center w-full lg:w-fit text-white font-medium'
            onClick={onClick}
          >
            <Link next href={menuLink} className='text-content-onDark'>
              <div
                className={cx(baseLinkStyles, {
                  'border-white border-dashed': activeDropdown && color,
                  'border-transparent': !color || (color && !activeDropdown),
                })}
              >
                {menuName}
              </div>
            </Link>
          </button>
        ) : null}
      </>
    );
  } else {
    // if there is a submenu
    return (
      <div className='hs-dropdown [--strategy:static] [--offset:1] lg:[--strategy:fixed] [--adaptive:none] lg:[--trigger:hover] lg:py-4'>
        {menuName ? (
          <button
            type='button'
            aria-label={menuName}
            className='flex items-center w-full text-white font-medium'
          >
            <div
              className={cx(baseLinkStyles, {
                'border-white border-dashed': activeDropdown && color,
                'border-transparent': !color || (color && !activeDropdown),
              })}
            >
              {menuName}
            </div>
            <div className='ms-2'>
              <ArrowDown width={16} height={16} />
            </div>
          </button>
        ) : null}
        <div className='hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] lg:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 lg:w-48 hidden z-10 bg-marketing-navy lg:shadow-md rounded lg:py-2 lg:px-2 before:absolute top-full before:-top-5 before:start-0 before:w-full before:h-5'>
          {filteredSubmenu.map((mItem, index) => {
            const activeMenuItem = mItem?.link === pathname;
            return (
              <div
                role='button'
                aria-label={mItem?.name ?? `${menuName} submenu ${index}`}
                key={mItem?.link}
                className='flex items-center gap-x-3.5 py-2 px-3 rounded hover:bg-brand-primaryShade focus:ring-2 focus:ring-blue-d1'
                onClick={onClick}
              >
                <Link
                  next={mItem?.newWindow !== true}
                  href={mItem?.link ?? ''}
                  className='text-content-onDark'
                >
                  <div
                    className={cx(baseLinkStyles, {
                      'border-white border-dashed': activeMenuItem,
                      'border-transparent': !activeMenuItem,
                    })}
                  >
                    {mItem?.name}
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};

export default Dropdown;
