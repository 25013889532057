import Link from '@/components/common/link/Link';
import type { PrMenuItem } from '@/graphql/gql/graphql';

type GroupProps = {
  name: PrMenuItem['name'];
  submenu: PrMenuItem['submenu'];
};

const Group = ({ name, submenu }: GroupProps) => {
  const filteredSubmenu = submenu ?? [];

  return (
    <div className='flex flex-col text-white'>
      <div className='font-bold mb-2'>{name}</div>
      {filteredSubmenu.map((mItem) => (
        <div key={mItem?.link} className='mb-2'>
          <Link
            next={mItem?.newWindow !== true}
            href={mItem?.link ?? ''}
            className='text-white'
          >
            {mItem?.name}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Group;
