import React, { useEffect } from 'react';
import Close from '@/components/svg/Close';

type DrawerProps = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const Drawer = ({ id, isOpen, onClose, children }: DrawerProps) => {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // Close the drawer if the overlay is clicked
      if (isOpen && target && !target.closest('.drawer-content')) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    <div
      id={id}
      className={`fixed inset-0 overflow-hidden z-50 ${
        isOpen ? 'pointer-events-auto' : 'pointer-events-none'
      }`}
    >
      {isOpen && <div className='fixed inset-0 bg-black opacity-50'></div>}
      <div
        className={`fixed inset-y-0 right-0 w-4/5 bg-marketing-navy shadow-lg transform transition-transform duration-300 drawer-content ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className={`flex justify-end p-6 text-content-onDark`}>
          <button aria-label='Close' type='button' onClick={onClose}>
            <Close width='24' height='24' />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Drawer;
