'use client';

import { useState, useEffect } from 'react';
import cx from 'classnames';
import Link from '@/components/common/link/Link';
import SearchInput from '@/components/header/SearchInput';
import Dropdown from '@/components/header/Dropdown';
import FanDuelLogoHorizontal from '@/components/svg/FanDuelLogoHorizontal';
import { HEADER_HEIGHT_LG } from '@/constants/header';
import type { PrMenuItem } from '@/graphql/gql/graphql';

type DesktopNavigationProps = {
  bgTransition: boolean;
  menuItems: (PrMenuItem | null)[];
};

const DesktopNavigation = ({
  menuItems,
  bgTransition,
}: DesktopNavigationProps) => {
  const [color, setColor] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const changeColor = () => {
      if (window.scrollY >= HEADER_HEIGHT_LG) {
        setColor(true);
      } else {
        setColor(false);
      }
    };

    if (bgTransition) {
      window.addEventListener('scroll', changeColor);
    }

    return () => window.removeEventListener('scroll', changeColor);
  });

  const baseStyles = `flex flex-wrap sm:justify-start sm:flex-nowrap py-3 sm:py-0 m-auto`;

  return (
    <header
      style={{ height: HEADER_HEIGHT_LG }}
      className={cx(baseStyles, {
        'bg-marketing-navy transition ease-in-out duration-300':
          bgTransition && color,
        'bg-transparent transition ease-in-out duration-300':
          bgTransition && !color,
        'bg-marketing-navy': !bgTransition,
      })}
    >
      <nav
        className='relative max-w-[76.875rem] w-[90%] mx-auto sm:flex sm:items-center sm:justify-between'
        aria-label='Global'
      >
        <div className='flex items-center justify-between'>
          <Link ariaLabelText='Header FanDuel Logo' next href='/'>
            <FanDuelLogoHorizontal height={42} width={193.5} fill='#FFFFFF' />
          </Link>
        </div>
        <div
          id='navbar-offcanvas'
          className='hs-overlay hs-overlay-open:translate-x-0 -translate-x-full fixed top-0 start-0 transition-all duration-300 transform h-full max-w-xs w-full z-[60] bg-none basis-full grow sm:order-2 sm:static sm:block sm:h-auto sm:max-w-none sm:w-auto sm:border-r-transparent sm:transition-none sm:translate-x-0 sm:z-40 sm:basis-auto dark:bg-gray-800 dark:border-r-gray-700 sm:dark:border-r-transparent hidden'
          data-tabindex='-1'
        >
          <div className='flex flex-col gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:ps-7'>
            {menuItems.map((mItem) => {
              return (
                <Dropdown
                  key={mItem?.name}
                  menuName={mItem?.name ?? ''}
                  menuLink={mItem?.link ?? ''}
                  submenu={mItem?.submenu}
                  color={color}
                />
              );
            })}
            <SearchInput
              name='query-desktop'
              expanded={expanded}
              setExpanded={setExpanded}
            />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default DesktopNavigation;
