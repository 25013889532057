import { SVGProps } from '@/types/svg';

const Svg = ({
  fill,
  height,
  width,
  children,
  viewBox,
  ...props
}: SVGProps) => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    height={height}
    width={width}
    viewBox={viewBox ?? `0 0 ${width} ${height}`}
    fill={fill}
    {...props}
  >
    {children}
  </svg>
);

export default Svg;
