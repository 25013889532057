'use client';

import { useState } from 'react';
import Link from '@/components/common/link/Link';
import Drawer from '@/components/common/drawer/Drawer';
import SearchInput from '@/components/header/SearchInput';
import Dropdown from '@/components/header/Dropdown';
import HamburgerMenu from '@/components/svg/HamburgerMenu';
import FanDuelLogoHorizontal from '@/components/svg/FanDuelLogoHorizontal';
import { HEADER_HEIGHT_SM } from '@/constants/header';
import type { PrMenuItem } from '@/graphql/gql/graphql';

type MobileNavigationProps = {
  menuItems: (PrMenuItem | null)[];
};

const MobileNavigation = ({ menuItems }: MobileNavigationProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <header
      style={{ height: HEADER_HEIGHT_SM }}
      className={`bg-marketing-navy flex justify-center align-items relative`}
    >
      {/* Toggle Drawer Button */}
      <button
        type='button'
        onClick={handleDrawerToggle}
        className='absolute top-[1.5rem] right-[1rem]'
        data-hs-overlay='#docs-sidebar'
        aria-controls='navigation-sidebar'
        aria-label='Toggle navigation'
      >
        <span className='sr-only'>Open Navigation</span>
        <HamburgerMenu width={24} height={24} />
      </button>

      {/* FanDuel Logo */}
      <Link ariaLabelText='Header FanDuel Logo' next href='/'>
        <FanDuelLogoHorizontal
          height={HEADER_HEIGHT_SM}
          width={140}
          fill='#FFFFFF'
        />
      </Link>

      {/* Navigation Drawer */}
      <Drawer
        id='navigation-sidebar'
        isOpen={isDrawerOpen}
        onClose={handleDrawerToggle}
      >
        <div className='flex flex-col p-5 gap-y-4 gap-x-0 lg:flex-row lg:gap-y-0 sm:gap-x-7 sm:ps-7'>
          <SearchInput
            name='query-mobile'
            expanded
            setIsDrawerOpen={setIsDrawerOpen}
          />
          {menuItems.map((mItem) => {
            return mItem?.link && mItem?.name ? (
              <Dropdown
                key={mItem?.name}
                menuName={mItem.name}
                menuLink={mItem.link}
                submenu={mItem?.submenu}
                onClick={handleDrawerToggle}
                color
              />
            ) : null;
          })}
        </div>
      </Drawer>
    </header>
  );
};

export default MobileNavigation;
