import Link from '@/components/common/link/Link';
import Image from '@/components/common/image/Image';
import { SOCIAL_MEDIA_LOGO } from '@/constants/menu';

const SocialMedia = () => {
  return (
    <div className='flex mt-4 justify-start lg:mt-8 lg:justify-end flex-nowrap'>
      <span>
        <Link next={false} href='https://www.facebook.com/fanduel'>
          <Image
            src={SOCIAL_MEDIA_LOGO.FB}
            alt='facebook-logo'
            width='40'
            height='40'
          />
        </Link>
      </span>
      <span className='ml-8'>
        <Link next={false} href='https://www.instagram.com/FanDuel'>
          <Image
            src={SOCIAL_MEDIA_LOGO.IG}
            alt='instagram-logo'
            width='40'
            height='40'
          />
        </Link>
      </span>
      <span className='ml-8'>
        <Link next={false} href='https://www.twitter.com/FanDuel'>
          <Image
            src={SOCIAL_MEDIA_LOGO.TW}
            alt='twitter-logo'
            width='40'
            height='40'
          />
        </Link>
      </span>
      <span className='ml-8'>
        <Link
          next={false}
          href='https://www.youtube.com/channel/UCbPP6F-3ASqkBkT9Obro-TQ'
        >
          <Image
            src={SOCIAL_MEDIA_LOGO.YT}
            alt='youtube-logo'
            width='40'
            height='40'
          />
        </Link>
      </span>
    </div>
  );
};

export default SocialMedia;
