export enum MENU {
  HEADER = 'Header',
  FOOTER = 'Footer',
}

export const SOCIAL_MEDIA_LOGO = {
  FB: 'https://cdn.sanity.io/images/pbwem9y5/fanduel_marketing_dev/39dc12bebccd63c461c8d3f277e0ad151030b4b6-61x61.png',
  IG: 'https://cdn.sanity.io/images/pbwem9y5/fanduel_marketing_dev/8422b136beeb5d42d0b2eaafdb98fb8e83dca937-61x61.png',
  TW: 'https://cdn.sanity.io/images/pbwem9y5/fanduel_marketing_dev/2a6b7d6fc644ed9f4916e296ab5fe6d051ffd4f3-61x61.png',
  YT: 'https://cdn.sanity.io/images/pbwem9y5/fanduel_marketing_dev/b9a9f4163e5684a1ccc0111dfb174c4ac928ab59-61x61.png',
};
